import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError, from } from 'rxjs';
import { map, catchError, finalize, mergeMap } from 'rxjs/operators';

import { LoadingController, AlertController, Platform } from '@ionic/angular';

import { StorageService } from 'src/app/services/storage.service';
import { LoadingService } from 'src/app/services/loading.service';

@Injectable()
export class InterceptorHelpers implements HttpInterceptor {
    usuario;
    countAlert = 0;
    appVersion = '7';

    constructor(
        private storageService: StorageService,
        private loadingService: LoadingService,
        public loadingController: LoadingController,
        private alertController: AlertController,
        private router: Router,
        private plt: Platform
    ) {
        
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const execRequest = this.execRequest(request, next);
        return from(execRequest).pipe(
            mergeMap( (req: Observable<HttpEvent<any>>) => {
                return req;
            })
        );
    }

    async execRequest(request: HttpRequest<any>, next: HttpHandler): Promise<any> {
        this.usuario = await this.storageService.getUsuario();
        if(!request.reportProgress)
            this.loadingService.on();

        let token = this.usuario ? this.usuario.token : '';
        let headers = new HttpHeaders()
        .append('Authorization', token)
        .append('Content-Type', 'application/json')
        .append('Versao', this.appVersion);

        request = request.clone({ headers });

        if (this.usuario) {
            const {matricula} = this.usuario;
            const versao = this.appVersion;
        }
        return this.handler(next, request);
    }

    handler(next, request) {
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    if (!event.body.status) {
                        this.trataError(event, request);
                    }
                    return event;
                }
            }),
            catchError(response => {
                this.renderError();
                return [];
            }),
            finalize(() => {
                setTimeout(() => {
                    this.loadingService.off()
                }, 500);
            })
        );
    }

    private trataError(event: any, request: any) {
        let redirect = false;
        let msgError = '';
        let headerError = 'Atenção';
        if (event.body.msg) {
            msgError = event.body.msg;
        }
        if (event.body.redirect) {
            redirect = true;
        }
        if (event.body.headerError) {
            headerError = event.body.headerError;
        }

        if (event.body.code === 1 || event.body.code === 2) {
            return false;
        }

        if (event.body.code != 0) {
            this.renderError(msgError, redirect, event.body.redirect, headerError);
        }
    }

    

    async renderError(msg: string = '', redirect: boolean = false, page: string = '', headerError?: string) {

        let msgError = 'Não foi possivel executar operação. Tente novamente mais tarde';
        if (msg) {
            msgError = msg;
        }

        if (!headerError) {
            headerError = 'Atenção';
        }
        
        if (this.countAlert === 0) {
            this.countAlert++;
            const alert = await this.alertController.create({
                cssClass: 'my-custom-class',
                header: headerError,
                message: msgError,
                buttons: [
                    {
                        text: 'Entendido',
                        cssClass: 'primary',
                        handler: async (e) => {
                            this.countAlert = 0;
                            if (page) {
                                if (page === 'login') {
                                    await this.storageService.deleteUsuario();
                                }
                                this.router.navigate([page]);
                            }
                        }
                    }
                ]
            });
            this.loadingService.off()
            await alert.present();
            
        }
    }
}
